import { envConfig } from "../config/zendeskConfig"
export class IgreZDClassicWidget {

  constructor(igreZDConfig, zendeskjsUrl) {
    this.igreZDConfig = igreZDConfig;
    this.zendeskjsUrl = zendeskjsUrl;
  }
  init() {
    const webWidget = this.getWebWidgetSetting();
    window.zESettings = {
      webWidget: webWidget
    }
    if (!document.getElementById("ze-snippet")) {
      console.log("Zendesk Classic Widget snippet not loaded, loading now");
      this.loadSnippet();
    } else {
      console.log("Zendesk Classic Widget snippet already loaded");
    }
  }

  update() {
    const webWidget = this.getWebWidgetSetting();
    zE("webWidget", "updateSettings", {
      webWidget: webWidget,
    })
    //reauthenticate after update
    zE(function () {
      zE("webWidget", "helpCenter:reauthenticate")
    })
  }

  openFeedback() {
    window.zESettings.webWidget.helpCenter.suppress = true;

    zE("webWidget", "updateSettings", window.zESettings.webWidget);
    zE("webWidget", "open");
    //this.hideCustomTicketFields();
  }

  openArticles() {
    window.zESettings.webWidget.helpCenter.suppress = false;

    zE("webWidget", "updateSettings", window.zESettings.webWidget);
    zE("webWidget", "open");
    //this.hideCustomTicketFields();
  }

  openChat() {
    window.zESettings.webWidget.helpCenter.suppress = true;
    if (window.zESettings.webWidget.chat) {
      window.zESettings.webWidget.chat.suppress = false;
    }

    zE("webWidget", "updateSettings", window.zESettings.webWidget);
    zE("webWidget", "open");
    //this.hideCustomTicketFields();
  }

  logout() {
    zE("webWidget", "logout");
  }


  getWebWidgetSetting() {

    const helpCenterToken = this.igreZDConfig.ZendeskTokens.HelpCenter;
    let authenticate = {
      jwtFn: function (callback) {
        console.log("Zendesk: Helpcenter Authentication")
        callback(helpCenterToken)
      },
    }

    //const ticketFormId = getDefaultTicketFormId();  //get default ticket form id from environment

    const contactForm = this.composeContactForm(this.igreZDConfig); //compose contactForm object

    let webWidget = {
      /*position: { horizontal: 'right', vertical: 'top' },
      offset: {
        horizontal: '10px',
        vertical: '70px'
      },
      authenticate: authenticate,
      contactOptions: {
        enabled: false,
        contactButton: { '*': 'Live Chat' },
        contactFormLabel: { '*': 'Leave us a message' },
        chatLabelOnline: { '*': 'Live Chat' },
        chatLabelOffline: { '*': 'Chat is unavailable' }
      },*/
      contactForm: contactForm,
      helpCenter: {
        originalArticleButton: this.igreZDConfig.originalArticleButton,
        suppress: true,
        title: {
          '*': 'FAQ'
        },
        searchPlaceholder: {
          '*': 'Search our Articles'
        },
        messageButton: {
          '*': 'Contact Us'
        },
        chatButton: {
          '*': 'Chat with us'
        }
      },
      launcher: {
        mobile: {
          labelVisible: false,
        },
        chatLabel: {
          '*': 'Chat'
        },
        label: {
          '*': 'Contact Us'
        },
      }
    }

    if (this.igreZDConfig.ZendeskTokens.HelpCenter !== "") {
      webWidget.authenticate = authenticate
    }
    if (this.igreZDConfig.filter && (this.igreZDConfig.filter.category || this.igreZDConfig.filter.label_names || this.igreZDConfig.filter.section)) {
      webWidget.helpCenter.filter = this.igreZDConfig.filter;
    }

    const chatToken = this.igreZDConfig.ZendeskTokens.Chat;
    if (!!chatToken && chatToken !== "") {
      webWidget.authenticate.chat = {
        jwtFn: function (callback) {
          console.log("Zendesk: Chat Authentication")
          callback(chatToken)
        }
      }
      webWidget.chat = {
        suppress: false,
        connectOnPageLoad: true,
        notifications: {
          mobile: {
            disable: true,
          }
        }
      }
    }
    console.log("ChatEnabled : " + this.igreZDConfig.ChatEnabled)
    if (this.igreZDConfig.ChatEnabled === false) {
      webWidget.chat = {
        suppress: true
      }
    }

    return webWidget;
  }


  composeContactForm() {
    let contactForm = {
      attachments: false,
      title: {
        '*': 'Contact us for support'
      },
      fields: [
        { id: "name", prefill: { "*": this.igreZDConfig.User.Name } },
        { id: "email", prefill: { "*": this.igreZDConfig.User.Email } },
      ]
    }
    /*
    if(this.igreZDConfig.TicketForm.Id) {
      let ticketFields = [];
      let fields = this.igreZDConfig.TicketForm.Fields;
      let Value;
      let Hidden;
      fields.forEach((item) => { 
        let field = item;
        if(field && field.Id) {
          Value = field.Value ? field.Value : "";
          Hidden = field.Hidden && field.Hidden == true ? true : false;
          ticketFields.push(
            {"id": field.Id, "prefill": {'*': Value}, "Hidden": Hidden}
          );
        }
      });
      contactForm.ticketForms = [{ "id": this.igreZDConfig.TicketForm.Id, "fields": ticketFields }]
    }
    */
    if (this.igreZDConfig.TicketFormName) {
      let ticketFormConfig;
      if (this.igreZDConfig.Env === "sandbox") {
        ticketFormConfig = envConfig.sandbox[this.igreZDConfig.TicketFormName];
      } else if (this.igreZDConfig.Env === "uat") {
        ticketFormConfig = envConfig.uat[this.igreZDConfig.TicketFormName];
      } else {
        ticketFormConfig = envConfig.production[this.igreZDConfig.TicketFormName];
      }

      let ticketFields = [];
      let fields = ticketFormConfig.Fields;
      let Value;
      let HiddenField = this.igreZDConfig.ZendeskTokens.HelpCenter === "" ? "HiddenForAnonymous" : "HiddenForSignedIn";
      let hidden;
      fields.forEach((item) => {
        let field = item;
        if (field && field.Id && field.Name) {
          Value = this.igreZDConfig.User[field.Name] ? this.igreZDConfig.User[field.Name] : "";
          hidden = field[HiddenField] && field[HiddenField] === true ? true : false;
          ticketFields.push(
            { "id": field.Id, "prefill": { '*': Value }, "Hidden": hidden }
          );
        }
      });
      if (ticketFormConfig.Id) {
        contactForm.ticketForms = [{ "id": ticketFormConfig.Id, "fields": ticketFields }]
      }
    }
    return contactForm;

  }

  hideCustomTicketFields() {
    let fields = window.zESettings.webWidget.contactForm.ticketForms[0].fields;
    let zendeskIframe = document.getElementById("webWidget").contentWindow;
    if (zendeskIframe) {
      fields.forEach((item) => {
        let field = item;
        if (field.Hidden && field.Hidden === true) {
          let element =
            zendeskIframe.document.getElementsByName("key:" + item.id)[0]
          if (element) {
            element.parentNode.style.display = "none"
          }
        }
      });
    }
  }


  loadSnippet = () => {
    this.loadScript(
      "ze-snippet",
      this.zendeskjsUrl,
      () => {
        console.log("Zendesk Classic Widget snippet loaded");
        zE(() => {
          //console.log("zE ready fired")
          //enable #zendesk-help-button
          /*const zendeskHelpButton = document.getElementById("zendesk-help-button")
          if (zendeskHelpButton) {
            removeClass(zendeskHelpButton, "zendesk-help-button-hide")
          }*/

          if (this.igreZDConfig.DefaultSearchSuggestion && this.igreZDConfig.DefaultSearchSuggestion !== "") {
            console.log("Zendesk: Setting DefaultSearchSuggestion");
            zE.setHelpCenterSuggestions({ search: this.igreZDConfig.DefaultSearchSuggestion });
          } else {
            console.log("Zendesk: No DefaultSearchSuggestion Available");
          }
          zE("webWidget:on", "close", function () {
            console.log("Zendesk: webWidget close fired!");
            window.zESettings.webWidget.helpCenter.suppress = true;
            zE("webWidget", "updateSettings", window.zESettings.webWidget);
          })
          zE("webWidget:on", "open", function () {
            console.log("Zendesk: webWidget open fired!");
          })
          let hideCustomTicketFields = this.hideCustomTicketFields;   
          zE('webWidget:on', 'userEvent', function (event) {
            //console.log(event);
            //console.log(event.category + ", " + event.action + "," + event.properties);
            //console.log(event.category , event.action, event.properties);
            if (event.action === "Contact Form Shown") {
              hideCustomTicketFields();
            }
          });
          zE('webWidget:on', 'chat:connected', function () {
            console.log('successfully connected to Zendesk Chat!');
          });
          zE('webWidget:on', 'chat:start', function () {
            console.log('successfully started a Zendesk Chat!');
          });


          //$zopim(() => {
          //  console.log("Zopim ready fired")
          //})
        })
      }
    )
  }

  loadScript(id, url, callback) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.id = id
    script.src = url

    // Several events for cross browser compatibility.
    script.onreadystatechange = callback
    script.onload = callback

    // Appending the element will trigger the script load
    document.head.appendChild(script)
  }

}






