
export class IgreZDMessagingWidget {
  constructor(igreZDConfig, zendeskjsUrl) {
    this.igreZDConfig = igreZDConfig;
    this.zendeskjsUrl = zendeskjsUrl;
  }

  init() {
    if (!document.getElementById("ze-snippet")) {
      console.log("Zendesk Messaging snippet not loaded, loading now");
      this.loadSnippet();
    } else {
      console.log("Zendesk Messaging snippet already loaded");
    }
  }

  update() {
    this.loginMessangingUser();
  }

  openFeedback() {
    zE("messenger", "open");
  }

  openArticles() {
    zE("messenger", "open");
  }

  openChat() {
    zE("messenger", "open");
  }

  logout() {
    zE("messenger", "logout");
  }

  loginMessangingUser() {
    try {
      const messagingJwtToken = this.igreZDConfig?.ZendeskTokens?.Chat;
      if (messagingJwtToken) {
        console.log("Zendesk Messaging init authentication")
        zE("messenger", "loginUser", function (callback) {
          callback(messagingJwtToken)
        })
      } else {
        console.log("Zendesk Messaging not authenticated for this user")
      }
    } catch (e) {
      console.log("Unable to authenticate Zendesk Messaging")
    }
  }

  loadSnippet() {
    this.loadScript("ze-snippet", this.zendeskjsUrl, () => {
      console.log("Zendesk Messaging snippet loaded");
      zE(() => {
        console.log("Zendesk triggering loginMessangingUser");
        this.loginMessangingUser();
      })
    })
  }

  loadScript(id, url, callback) {
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.id = id
    script.src = url

    // Several events for cross browser compatibility.
    script.onreadystatechange = callback
    script.onload = callback

    // Appending the element will trigger the script load
    document.head.appendChild(script)
  }
}
