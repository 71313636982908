import "./index.scss"
//import { toggleClass, addClass, removeClass } from "../utils"
import { envConfig } from "../config/zendeskConfig"
import { validateAndBuildConfigObj } from "./zendeskUtil"
import { IgreZDClassicWidget } from "./igreZDClassicWidget";
import { IgreZDMessagingWidget } from "./igreZDMessagingWidget";
import { LaunchDarklyUtil } from "./../launchdarkly/index";

let igreZendeskWidget;
let launchDarklyUtil;

export const initZendesk = async (config, tokens) => {
  igreZendeskWidget = await createIgreZendeskWidget(config, tokens);
  igreZendeskWidget.init();
}

export const updateZendesk = async (config, tokens) => {
  igreZendeskWidget = await createIgreZendeskWidget(config, tokens);
  igreZendeskWidget.update();
}


const createIgreZendeskWidget = async (config, tokens) => {
  const igreZDConfig = await validateAndBuildConfigObj(config, tokens);

  let zendeskjsUrl;

  if (igreZDConfig.Env === "sandbox") {
    zendeskjsUrl = envConfig.sandbox.zendeskjsUrl;
    launchDarklyUtil = new LaunchDarklyUtil(envConfig.sandbox.LaunchDarklyClientSideID);
  } else if (igreZDConfig.Env === "uat") {
    zendeskjsUrl = envConfig.uat.zendeskjsUrl;
    launchDarklyUtil = new LaunchDarklyUtil(envConfig.uat.LaunchDarklyClientSideID);
  } else {
    zendeskjsUrl = envConfig.production.zendeskjsUrl;
    launchDarklyUtil = new LaunchDarklyUtil(envConfig.production.LaunchDarklyClientSideID);
  }

  // zendeskjsUrl = envConfig[igreZDConfig.Env].zendeskjsUrl;
  // launchDarklyUtil = new LaunchDarklyUtil(envConfig[igreZDConfig.Env].LaunchDarklyClientSideID);

  // TODO: LaunchDarkly call goes hear
  let ZendeskMessagingEnableFlag = await launchDarklyUtil.getLDFlag(envConfig[igreZDConfig.Env].LDZendeskMessagingEnableFlagName, false);
  console.log(`ZendeskMessagingEnableFlag: ${ZendeskMessagingEnableFlag}. and ${typeof ZendeskMessagingEnableFlag}`);
  // fallback code if LaunchDarkly failed
  if (ZendeskMessagingEnableFlag) {
    //if (igreZDConfig.Env === "sandbox") {
    console.log(`IgreZDMessagingWidget created`);
    return new IgreZDMessagingWidget(igreZDConfig, zendeskjsUrl);
  } else {
    console.log(`IgreZDClassicWidget created`);
    return new IgreZDClassicWidget(igreZDConfig, zendeskjsUrl);
  }
}

export const openFeedback = () => {
  igreZendeskWidget.openFeedback();
}

export const openArticles = () => {
  igreZendeskWidget.openArticles();
}

export const openChat = () => {
  igreZendeskWidget.openChat();
}

export const logout = () => {
  igreZendeskWidget.logout();
}


export const getZendeskTokens = async (config) => {
  let zendeskTokens = {
    "Chat": "",
    "HelpCenter": ""
  }
  if (config?.User?.Type && config?.IdToken != "") {
    let chatEnabled = (!config.ChatEnabled) ? false : true;
    let zendeskApiUrl = getZendeskApiBaseUrl(config) + config.User.Type + "?usedefaultuser=y";  //"https://hostname/ignrezendesk/tokens/{type}"
    if (chatEnabled == false) {
      zendeskApiUrl = zendeskApiUrl + "&chatenabled=n";
    }

    let headers = { "Content-Type": "application/json", "Authorization": config.IdToken }
    let now = Math.floor(new Date().getTime() / 1000);
    let firstName = config.User.FirstName ? config.User.FirstName : (config.User.Name ? config.User.Name : "");
    const lastName = config.User.LastName ? config.User.LastName : "";
    const fullName = (firstName + " " + lastName).trim();
    let payload = { "Now": now, "Name": fullName, "Email": config.User.Email }
    try {
      let res = await fetch(zendeskApiUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(payload),
      });

      if (res.ok) {
        const data = await res.json();
        if (data) {
          if (data.Chat && data.Chat != "")
            zendeskTokens.Chat = data.Chat;
          if (data.HelpCenter && data.HelpCenter != "")
            zendeskTokens.HelpCenter = data.HelpCenter;
        }
      } else {
        window.postMessage({ "IGNR_MESSAGE_ID": "IGNRE-ZENDESK-TOKEN-API", "Code": res.status, "message": res.statusText }, "*");
      }

    } catch (error) {
      console.log(error);
      window.postMessage({ "IGNR_MESSAGE_ID": "IGNRE-ZENDESK-TOKEN-API", "Code": 500, "message": error }, "*");
    }
  }

  return zendeskTokens;
}


const getZendeskApiBaseUrl = (config) => {
  if (config?.Env?.toLowerCase() === "sandbox") {
    return envConfig.sandbox.tokenapi;
  } else if (config?.Env?.toLowerCase() === "uat") {
    return envConfig.uat.tokenapi;
  } else {
    return envConfig.production.tokenapi;
  }

}

