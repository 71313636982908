import { initialize } from 'launchdarkly-js-client-sdk';

let ldclient = null;

export class LaunchDarklyUtil {
    constructor(clientSideID) {
        this.clientSideID = clientSideID;
        this.init();
    }

    init() {
        if (ldclient == null) {
            try {
                // Set up the anonymous context pre-login. Later call identify() to set up actual user context post-login 
                let context = {
                    kind: "user", // "user",
                    key: "anonymous", // "example-user-key",
                };
                ldclient = initialize(this.clientSideID, context);
            }
            catch (err) {
                console.log(`Error LaunchDarklyUtil.init()`);
                console.log(err);
            }
        }
    }

    async getAllLDFlags(userEmail = '', userName = '', userId = 'anonymous', userType = 'user') {
        try {
            this.init();
            let newContext = {
                kind: userType,
                key: userId
            };
            // Add only if userEmail is not null/undefined/empty string
            if (userEmail) {
                newContext.email = userEmail;
            }
            // Add only if userName is not null/undefined/empty string
            if (userName) {
                newContext.name = userName;
            }
            return ldclient.identify(newContext);
        }
        catch (err) {
            console.log(`Error in LaunchDarklyUtil.getAllLDFlags()`);
            console.log(err);
        }
        return {};
    }

    async getLDFlag(launchDarklyFlagName, defaultValue, userEmail = '', userName = '', userId = 'anonymous', userType = 'user') {
        try {
            let allFlags = await this.getAllLDFlags(userEmail, userName, userId, userType);
            console.log('All LD flags');
            console.log(JSON.stringify(allFlags));
            console.log(`Flag value for ${launchDarklyFlagName} is ${allFlags[launchDarklyFlagName]}`);
            if (Object.hasOwn(allFlags, launchDarklyFlagName))
                return allFlags[launchDarklyFlagName];
        }
        catch (err) {
            console.log(`Error in LaunchDarklyUtil.getLDFlag()`);
            console.log(err);
        }
        return defaultValue;
    }
}